<template>
  <div class="lang-group">
    <h4 :style="{ color: myColors.main }">{{ title }}</h4>
    <Language
      v-for="(item, index) in langData.languages"
      :key="index"
      :exp="item.experience"
      :name="item.name"
    />
  </div>
</template>

<script>
import Language from "@/components/info/Language.vue";
import { colorMixin } from "@/core/mixins/color_mixin.js";

export default {
  mixins: [colorMixin],
  components: {
    Language,
  },
  props: {
    title: {
      type: String,
      default: "Title here",
    },
    langData: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.lang-group {
  display: grid;
  grid-template-rows: 2rem 1fr;
  inline-size: 100%;
  box-sizing: border-box;
  /*padding-inline-end: 2rem;*/
}

h4 {
  font-size: 1.125rem;
  line-height: 1.437rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
}
</style>
