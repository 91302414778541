<template>
  <div class="language">
    <p :style="{ color: myColors.foreground }">{{ name }}</p>
    <div :style="{ 'border-color': myColors.main }" class="progress-bar">
      <div
        :style="{ 'background-color': myColors.main, width: `${exp}%` }"
        class="fill"
      ></div>
    </div>
  </div>
</template>

<script>
import { colorMixin } from "@/core/mixins/color_mixin.js";

export default {
  mixins: [colorMixin],
  props: {
    name: {
      type: String,
      default: "Some language",
    },
    exp: {
      type: Number,
      default: 50,
    },
  },
};
</script>

<style scoped>
.language {
  display: grid;
  grid-template-columns: 7rem auto;
  align-items: center;
  margin: 1rem 0 1rem 0;
  font-weight: 600;
  inline-size: 100%;
}

.progress-bar {
  inline-size: 100%;
  block-size: 0.5rem;
  inline-size: 100%;
  border-radius: 2rem;
  border: 1px solid;
}

@media (max-width: 480px) {
  .progress-bar {
    width: auto;
  }
}

.fill {
  height: 100%;
}
</style>
